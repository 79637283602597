<template>
  <el-container class="patroladd">
    <el-header class="box add bb">
       <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item>权限管理</el-breadcrumb-item>
       <el-breadcrumb-item >角色权限</el-breadcrumb-item>
        <el-breadcrumb-item v-if="page.role_id">编辑角色用户</el-breadcrumb-item>
         <el-breadcrumb-item v-else>新增角色用户</el-breadcrumb-item>
    </el-breadcrumb>
      <div>
        <el-button class="back_btn" size="medium" plain @click="$router.back()">返回</el-button>
      </div>
    </el-header>
    <el-main class="pt0">
        <el-row class="box mt20 f14">
          <el-col :span="11" class="tl" >
            <div class="patroladd-title1 f14 bgcfbe tl pl15 pr15">该组用户</div>
             <el-form :inline="true" status-icon ref="page" :model="page" class="demo-ruleForm mt15">
             <el-col :span="8" class="mr10">
             <el-form-item size="medium" >
               <el-input placeholder="请输入姓名或者电话号码" clearable v-model="page.user_name"  @clear='clear(1)'  class="input-with-select">
               </el-input>
             </el-form-item>
              </el-col>
             <el-col :span="4" class="tl mr10">
              <el-form-item  size="medium">
               <el-button  type="primary"  @click="onSubmit(1)" class="general_bgc general_border cfff">查询</el-button>
              </el-form-item>
             </el-col>
              </el-form>
             <el-table :data="user_list"  border style="width: 100%;min-height:580px">
              <el-table-column prop="user_name" label="姓名" align='center'  width="100"> </el-table-column>
               <el-table-column prop="user_mobilephone" label="电话" align='center' width="180"> </el-table-column>
               <el-table-column  label="操作" class-name='aa' min-width="130"  fixed="right"  align='center'>
                <template slot-scope="scope">
                 <el-button type="primary" size="mini"  @click="todelete(scope.row.user_id)">移除该组</el-button>
                </template>
               </el-table-column>
            </el-table>
            <div class="i-page fr disib mt20">
             <el-pagination background :page-size='10' layout="total,prev, pager, next"  :pager-count="5" :total="total" :current-page="currentPage" @current-change = 'currentChange'></el-pagination>
            </div>
          </el-col>
          <el-col :span="11" class="tl" :offset="2">
            <div class="patroladd-title1 f14 bgcfbe tl pl15 pr15">不在该组用户</div>
            <el-form :inline="true" status-icon ref="page1" :model="page1" class="demo-ruleForm mt15">
             <el-col :span="8" class="mr10">
             <el-form-item size="medium" >
               <el-input placeholder="请输入姓名或者电话号码" clearable @clear='clear(2)' v-model="page1.user_name" class="input-with-select">
               </el-input>
             </el-form-item>
              </el-col>
             <el-col :span="4" class="tl mr10">
              <el-form-item  size="medium">
               <el-button  type="primary"  @click="onSubmit(2)" class="general_bgc general_border cfff">查询</el-button>
              </el-form-item>
             </el-col>
              </el-form>
            <el-table :data="tableData"  border style="width: 100%">
              <el-table-column prop="user_name" label="姓名" align='center'  width="100"> </el-table-column>
               <el-table-column prop="user_mobilephone" label="电话" align='center' width="180"> </el-table-column>
               <el-table-column  label="操作" class-name='aa' min-width="130"  fixed="right"  align='center'>
                <template slot-scope="scope">
                 <el-button type="primary" size="mini"  @click="add(scope.row.user_id)">添加到该组</el-button>
                </template>
               </el-table-column>
            </el-table>
            <div class="i-page fr disib mt20">
             <el-pagination background :page-size='10' layout="total,prev, pager, next"  :pager-count="5" :total="total1" :current-page="currentPage1" @current-change = 'currentChange1'></el-pagination>
            </div>
          </el-col>
          
        </el-row>
     
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { local,remove,session} from "../../../util/util";
export default {
  data() {
    return {
      menu_list: "",
      formInline: {
        role_id: "",
        role_name: "",
        menu_list: []
      },
      page: {
        pager_offset: "0",
         user_name:'',
        role_id:'',
      },
       page1: {
        pager_offset: "0",
        role_id: "",
        user_name:'',
      },
      user_list: [],
      total:0,
      total1:0,
      currentPage1: 1,
       currentPage: 1,
       tableData: [],
    };
  },
  created() {
    this.user = local.get("user");
      this.page.role_id=session.get('params').id?String(session.get('params').id):'';
       this.page1.role_id=session.get('params').id?String(session.get('params').id):'';
     this.currentPage = this.page.pager_offset / 10 + 1;
     this.currentPage1 = this.page1.pager_offset / 10 + 1;
    this.init(this.page);
     this.init1(this.page1);
    // this.getalluser(this.page)
  },
  methods: {
    init(params) {
      axios.get("/pc/role/user-list", params)
        .then(v => {
          this.user_list = v.data.user_list;
           this.total= v.pager_count
        });
      
    },
    init1(params){
      axios.get("/pc/role/nexist-user-list", params).then(v => {
        this.tableData=v.data.user_list;
         this.total1 = v.data.pager_count
        
      });
    },
    clear(index){
        this.onSubmit(index)
    },
     currentChange(page){
        var that=this;
      that.currentPage = page;
       that.page.pager_offset = String((page - 1) * 10)
      that.init(that.page)
    },
     currentChange1(page){
        var that=this;
      that.currentPage1 = page;
       that.page1.pager_offset = String((page - 1) * 10)
      that.init1(that.page1)
    },
    add(index) {
       axios.post("/pc/user-role/save", {user_id:index,role_id:this.page1.role_id}).then(v => {
        this.init1(this.page1);
         this.init(this.page);
            });
    },
    todelete(index){
        axios.delete("/pc/user-role/delete", {user_id:String(index),role_id:this.page.role_id}).then(v => {
           if(this.total%10==1){
           this.page.pager_offset = String(this.page.pager_offset-10)
         }
        this.init1(this.page1);
         this.init(this.page);
            });
    },
     onSubmit (index) { 
      if(index==1){
         this.currentPage = 1
      this.page.pager_offset = '0'
      this.init(this.page)
      }else{
         this.currentPage1 = 1
      this.page1.pager_offset = '0'
      this.init1(this.page1)
      }
     
    },
  }
};
</script>
<style lang="scss" scoped>
.patroladd {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-image {
    width: 160px;
    height: 160px;
    margin-right: 10px;
  }
  .back_btn {
    background: rgba(247, 248, 249, 1);
    border: 1px solid rgba(217, 222, 225, 1);
  }
  .patroladd-title {
    height: 34px;
    line-height: 34px;
    border-left: 2px solid #788287;
  }
  .patroladd-title1 {
    height: 43px;
    line-height: 43px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .el-checkbox {
    display: flex;
    justify-content: left;
    align-items: center;
  }
  /deep/ .el-dialog {
    width: 60% !important;
  }
  /deep/ .el-col-8 .el-form-item__content {
    width: 100%;
}
}
</style>
